<template>
  <div v-cloak v-if="($route.name == 'CoinClothingBackpack') || ($route.name == 'CoinClothing') || ($route.name == 'CryptoBackpacks')">

    <div class="grid-product">

      <div class="crypto-product">

        <div class="photo-slider">
          <div style="top:10px;" class="photo-slider-btn" v-bind:class="{ photoSliderBtnActive: !sliderPic2 }" @click="sliderPic2 = false"></div>
          <div style="top:40px;" class="photo-slider-btn" v-bind:class="{ photoSliderBtnActive: sliderPic2 }" @click="sliderPic2 = true"></div>
        </div>

        <router-link :to="'/merch/'+title+'/backpack'">
          <img v-show="!sliderPic2" :src="'/'+'merch/'+title+'-'+$parent.$parent.coins[title].symbol.toLowerCase()+'-backpack-1.jpg'+version" width="215" style="margin-top:23px;" draggable="false" :alt="$parent.$parent.coins[title].name +' ('+ $parent.$parent.coins[title].symbol +') All-Over Print Minimalist Crypto Backpack'">
          <img v-show="sliderPic2" :src="'/'+'merch/'+title+'-'+$parent.$parent.coins[title].symbol.toLowerCase()+'-backpack-2.jpg'+version" width="285" style="margin-top:-10px;"  draggable="false" :alt="$parent.$parent.coins[title].name +' ('+ $parent.$parent.coins[title].symbol +') All-Over Print Minimalist Crypto Backpack'">
        </router-link>
        
      </div>

      <div class="product-content">
        <router-link :to="'/merch/'+title+'/backpack'"><span class="product-title">{{ $parent.$parent.coins[title].name }} ({{ $parent.$parent.coins[title].symbol }}) Minimalist Backpack</span></router-link>
        <br>
        <router-link :to="'/merch/'+title+'/backpack'" class="link-details link-hover">All-Over Print</router-link>
        <br>
        <br>
        <span>
          Price: ${{backpackPrice}}
          <br>
          <br>
          Quantity: <input type="number" v-model="amount" value="1" style="width:40px;">
          <div style="height:25px;"></div>
          
          <button v-if="available" @click="addToCart('cc-backpack')">Add to cart</button>
          <button v-else disabled>Out of Stock</button>
        
          <div v-if="showAddedLink" style="margin-top:10px;font-size:14px;">
            Added to <router-link to="/shopping-cart" class="underline">Shopping Cart</router-link>
          </div>
        </span>
      </div>
      
    </div>

    <div style="clear:both;padding-top:40px;" v-if="$route.name == 'CoinClothingBackpack'">

      <b>Description</b>
      <br><br>
      A spacious {{ $parent.$parent.coins[title].name }} ({{ $parent.$parent.coins[title].symbol }}) backpack for people on the go! An all-over print minimalist backpack that's made from a water-resistant material. It has a large inside pocket for a laptop, and a hidden pocket that's perfect for keeping valuable items safe and accessible while traveling.
      <br>
      <br>
      <ul>
        <li>Made from 100% polyester</li>
        <li>Fabric weight: 9.91 oz/yd² (336 g/m²)</li>
        <li>Dimensions: H 16.1" (41cm), W 12.2" (31cm), D 5.5" (14cm)</li>
        <li>Capacity: 5.3 gallons (20l)</li>
        <li>Max weight: 44lbs (20kg)</li>
        <li>Water-resistant material</li>
        <li>Large inside pocket with a separate pocket for a 15” laptop, a hidden pocket with zipper on the back of the bag</li>
        <li>Top zipper has 2 sliders, and there are zipper pullers attached to each slider</li>
        <li>Silky lining, piped inside hems, and a soft mesh back</li>
        <li>Padded ergonomic bag straps from polyester with plastic strap regulators</li>
        <li>Printed, cut, and hand-sewn by an expert in-house team</li>
      </ul>
    </div>

  </div>
</template>

<script>

export default {
  name: 'CryptoBackpack',
  props: [ 'coin', 'available' ],
  data() {
    return {
      version: '',
      
      amount: 1,
      showAddedLink: false,
      sliderPic2: false,
    }
  },
  beforeMount() {
    this.title = this.coin;
    this.version = assetsVersion;
  },
  mounted() {

  },
  computed: {
    backpackPrice() {
      return this.$parent.$parent.products['cc-backpack'].sizesPrices['Standard']
    }
  },
  methods: {

    addToCart: function () {

      this.showAddedLink = true;

      let item_name = 'Backpack';
      let item_name_full = this.$parent.$parent.coins[this.coin].name+' ('+this.$parent.$parent.coins[this.coin].symbol+') ' + item_name;
      let item_id = 'cc-backpack-'+this.coin;
      let item_price = this.backpackPrice;
      let item_amount = this.amount;

      dexie.products.put({item_id: item_id, item_name: item_name, item_name_full: item_name_full, item_price: item_price, item_amount: item_amount, coin_id: this.coin })
      .then((id) => {
        this.$parent.$emit('countRefresh');
      });
    },

    dressSecond() {
      this.sliderPic2 = true;
    },

    dressFirst() {
      this.sliderPic2 = false;
    },

  }
};
</script>