<template>
  <div v-cloak v-if="($route.name == 'CoinClothingMask') || ($route.name == 'CoinClothing') || ($route.name == 'CryptoMasks')">

    <div class="grid-product">
      
      <div class="crypto-product">

        <div class="photo-slider">
          <div style="top:10px;" class="photo-slider-btn" v-bind:class="{ photoSliderBtnActive: !sliderPic2 }" @click="sliderPic2 = false"></div>
          <div style="top:40px;" class="photo-slider-btn" v-bind:class="{ photoSliderBtnActive: sliderPic2 }" @click="sliderPic2 = true"></div>
        </div>

        <router-link :to="'/merch/'+title+'/neck-gaiter'">
          <img v-show="!sliderPic2" :src="'/'+'merch/'+title+'-'+$parent.$parent.coins[title].symbol.toLowerCase()+'-neck-gaiter-1.jpg'+version" width="270" draggable="false"  :alt="$parent.$parent.coins[title].name +' ('+ $parent.$parent.coins[title].symbol +') Neck Gaiter, Crypto Face Mask'">
          <img v-show="sliderPic2" :src="'/'+'merch/'+title+'-'+$parent.$parent.coins[title].symbol.toLowerCase()+'-neck-gaiter-2.jpg'+version" width="270" draggable="false"  :alt="$parent.$parent.coins[title].name +' ('+ $parent.$parent.coins[title].symbol +') Neck Gaiter, Crypto Face Mask'">
        </router-link>
        
      </div>

      <div class="product-content">
        <router-link :to="'/merch/'+title+'/neck-gaiter'"><span class="product-title">{{ $parent.$parent.coins[title].name }} ({{ $parent.$parent.coins[title].symbol }}) Neck Gaiter</span></router-link>
        <br>
        <router-link :to="'/merch/'+title+'/neck-gaiter'" class="link-details link-hover">All-Over Print</router-link>
        <br>
        <br>
        
        <span>
          Price: ${{maskPrice}}
          <br>
          <br>
          Quantity: <input type="number" v-model="amount" value="1" style="width:40px;">
          <div style="height:25px;"></div>

          
          <button  v-if="available" @click="addToCart('cc-mask')">Add to cart</button>
          <button v-else disabled>Out of Stock</button>
        
          <div v-if="showAddedLink" style="margin-top:10px;font-size:14px;">
            Added to <router-link to="/shopping-cart" class="underline">Shopping Cart</router-link>
          </div>
        </span>

      </div>

    </div>

    <div style="clear:both;padding-top:40px;" v-if="$route.name == 'CoinClothingMask'">

      <b>Description</b>
      <br><br>
      This all-over print {{ $parent.$parent.coins[title].name }} ({{ $parent.$parent.coins[title].symbol }}) neck gaiter can be used as a face covering, headband, bandana, wristband, balaclava, and neck warmer.
      <br><br>
      <ul>
        <li>95% polyester, 5% elastane (fabric composition may vary by 1%)</li>
        <li>Fabric weight: 6.19 oz/yd² (210 g/m²)</li>
        <li>Breathable fabric</li>
        <li>Washable and reusable</li>
        <li>Four-way stretch fabric that stretches and recovers on the cross and lengthwise grains</li>
        <li>One size</li>
      </ul>
      <br>
      <b>Attention!</b> The product is intended to be used as an accessory. The product is not intended to act as a medical device or other medical product, and should not be used as a replacement for conventional and approved personal protective equipment, including surgical masks or respirators.
      <br><br>
      <a class="underline" href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/cloth-face-cover.html" target="_blank">CDC suggests</a> wearing face cloth coverings in public settings where other social distancing measures are difficult to maintain. This additional safety measure helps protect others in case you have coronavirus, but don't show any symptoms.
      <br><br>
      You should still follow other <a class="underline" href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/prevention.html" target="_blank">protective measures</a> as well, and closely follow the latest recommendations from public health officials and CDC to make an informed decision about the use of these masks.
    </div>

  </div>
</template>

<script>

export default {

  name: 'CryptoMask',
  props: [ 'coin', 'available' ],
  data() {
    return {
      version: '',
      amount: 1,
      showAddedLink: false,
      sliderPic2: false,
    }
  },
  beforeMount() {
    this.title = this.coin;
    this.version = assetsVersion;
  },
  mounted() {
  },
  computed: {
    maskPrice() {
      return this.$parent.$parent.products['cc-mask'].sizesPrices['Standard']
    }
  },
  methods: {

    addToCart: function (type) {

      this.showAddedLink = true;

      let item_name = 'Mask';
      let item_name_full = this.$parent.$parent.coins[this.coin].name+' ('+this.$parent.$parent.coins[this.coin].symbol+') Neck Gaiter (Face Mask)';
      let item_id = 'cc-mask-'+this.coin;
      let item_price = this.maskPrice;
      let item_amount = this.amount;

      dexie.products.put({item_id: item_id, item_name: item_name, item_name_full: item_name_full, item_price: item_price, item_amount: item_amount, coin_id: this.coin })
      .then((id) => {
        this.$parent.$emit('countRefresh');
      });
    },

    dressSecond() {
      this.sliderPic2 = true;
    },

    dressFirst() {
      this.sliderPic2 = false;
    },

  }
};
</script>