import Vue from 'vue'
import Router from 'vue-router'
import Home from './components/Home.vue'
import Home_cc from './components/Home_cc.vue'


import Page from "./components/Page.vue";
import CryptoLogo from "./components/CryptoLogo.vue";

import CoinClothing from "./components/merch/CoinClothing.vue";
import SingleApocalypseWow from './components/merch/SingleApocalypseWow.vue';
import SingleCryptoPoster from './components/merch/SingleCryptoPoster.vue';

import PageCategory from './components/merch/PageCategory.vue'
import CryptoFashion from './components/merch/CryptoFashion.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: "CryptoLogos",
      component: Home
    },
    {
      path: "/about",
      name: "about",
      component: Page
    },
    {
      path: "/history",
      name: "history",
      component: Page
    },
    {
      path: "/guides",
      name: "guides",
      component: Page
    },
    {
      path: "/faq",
      name: "faq",
      component: Page
    },



    // CC
    
    {
      path: '/merch',
      name: "Home",
      component: Home_cc
    },
    {
      path: '/merch/dogecoin/apocalypse-wow-meme-t-shirt',
      name: "SingleApocalypseWow",
      component: SingleApocalypseWow
    },
    {
      path: '/merch/crypto-fashion/crypto-logos-poster',
      name: "CryptoLogosPoster",
      component: SingleCryptoPoster
    },
    {
      path: '/merch/crypto-dresses',
      name: "CryptoDresses",
      component: PageCategory,
    },
    {
      path: '/merch/crypto-t-shirts',
      name: "CryptoTshirts",
      component: PageCategory
    },
    {
      path: '/merch/crypto-hoodies',
      name: "CryptoHoodies",
      component: PageCategory
    },
    {
      path: '/merch/crypto-sweatshirts',
      name: "CryptoSweatshirts",
      component: PageCategory
    },
    {
      path: '/merch/crypto-longsleeve-t-shirts',
      name: "CryptoLongsleeveTshirts",
      component: PageCategory
    },
    {
      path: '/merch/crypto-laptop-cases',
      name: "CryptoLaptopSleeves",
      component: PageCategory
    },
    {
      path: '/merch/crypto-mugs',
      name: "CryptoMugs",
      component: PageCategory
    },
    {
      path: '/merch/crypto-stickers',
      name: "CryptoStickers",
      component: PageCategory
    },
    {
      path: '/merch/crypto-backpacks',
      name: "CryptoBackpacks",
      component: PageCategory
    },
    {
      path: '/merch/crypto-face-masks',
      name: "CryptoMasks",
      component: PageCategory
    },
    {
      path: '/merch/crypto-fashion',
      name: "CryptoFashion",
      component: CryptoFashion
    },
    {
      path: '/shopping-cart',
      name: "ShoppingCart",
      component: Home_cc
    },
    {
      path: "/terms-and-conditions",
      name: "terms-and-conditions",
      component: Page
    },
    {
      path: "/privacy-policy",
      name: "privacy-policy",
      component: Page
    },
    {
      path: "/shipping",
      name: "shipping",
      component: Page
    },
    {
      path: "/payment-success",
      name: "payment-success",
      component: Page
    },
    {
      path: "/payment-declined",
      name: "payment-declined",
      component: Page
    },
    {
      path: "/merch/:coinId",
      name: "CoinClothing",
      component: CoinClothing
    },
    {
      path: "/merch/:coinId/t-shirt",
      name: "CoinClothingTshirt",
      component: CoinClothing,
    },
    {
      path: "/merch/:coinId/longsleeve-t-shirt",
      name: "CoinClothingLongsleeveTshirt",
      component: CoinClothing,
    },
    {
      path: "/merch/:coinId/laptop-sleeve",
      name: "CoinClothingLaptopSleeve",
      component: CoinClothing,
    },
    {
      path: "/merch/:coinId/hodl-t-shirt",
      name: "CoinClothingHodlTshirt",
      component: CoinClothing,
    },
    {
      path: "/merch/:coinId/dress",
      name: "CoinClothingDress",
      component: CoinClothing,
    },
    {
      path: "/merch/:coinId/hoodie",
      name: "CoinClothingHoodie",
      component: CoinClothing,
    },
    {
      path: "/merch/:coinId/sweatshirt",
      name: "CoinClothingSweatshirt",
      component: CoinClothing,
    },
    {
      path: "/merch/:coinId/coffee-mug",
      name: "CoinClothingMug",
      component: CoinClothing,
    },
    {
      path: "/merch/:coinId/sticker",
      name: "CoinClothingSticker",
      component: CoinClothing,
    },
    {
      path: "/merch/:coinId/backpack",
      name: "CoinClothingBackpack",
      component: CoinClothing,
    },
    {
      path: "/merch/:coinId/neck-gaiter",
      name: "CoinClothingMask",
      component: CoinClothing,
    },





    // {
    //   path: "/nft",
    //   name: "nft",
    //   component: Page
    // },
    {
      path: "/:coinId",
      name: "CryptoLogo",
      component: CryptoLogo
    },

  ]
})
