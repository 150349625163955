<template>
  <div v-cloak v-if="($route.name == 'CoinClothingMug') || ($route.name == 'CoinClothing') || ($route.name == 'CryptoMugs')">
    
    <div class="grid-product">
      <router-link :to="'/merch/'+title+'/coffee-mug'">
        <div class="crypto-product">
          <img :src="'/'+'merch/'+title+'-'+$parent.$parent.coins[title].symbol.toLowerCase()+'-coffee-mug.jpg'+version" width="270" class="select-none" draggable="false" style="margin-top:-10px;"  :alt="$parent.$parent.coins[title].name +' ('+ $parent.$parent.coins[title].symbol +') Crypto Coffee Mug'">
          <!-- <img :src="homeThemeAssetsPath+'mug.jpg?v=001'" width="230px" class="select-none" draggable="false">
          <div class="coin-mug"><div class="coin-mug-inner"><img draggable="false" class="coin-mug-img select-none" :src="'/'+'crypto-logos/pub/thumbs/'+title+'.png'"></div></div> -->
        </div>
      </router-link>

      <div class="product-content">
        <router-link :to="'/merch/'+title+'/coffee-mug'">
          <span class="product-title">{{ $parent.$parent.coins[title].name }} ({{ $parent.$parent.coins[title].symbol }}) Coffee Mug</span>
          <br>
          White Glossy Mug
        </router-link>
        <br>
        <br>
      
        <span>
          Price: ${{mugPrice}}
          <br>
          <br>
          Size:
          <select v-model="mugSize">
            <option v-for="(value, key) in $parent.$parent.products['cc-mug'].sizesPrices" :key="key" v-bind:value="key">
              {{ key }}
            </option>
          </select>
          &nbsp;
          Quantity: <input type="number" v-model="mugAmount" value="1" style="width:40px;">
          <div style="height:25px;"></div>
          
          <button v-if="available" @click="addToCart('cc-mug')">Add to cart</button>
          <button v-else disabled>Out of Stock</button>

          <div v-if="showAddedLinkMug" style="margin-top:10px;font-size:14px;">
            Added to <router-link to="/shopping-cart" class="underline">Shopping Cart</router-link>
          </div>
        </span>
      </div>
    </div>

    <div style="clear:both;padding-top:40px;" v-if="$route.name == 'CoinClothingMug'">
      <b>Description</b>
      <br><br>
      This sturdy {{ $parent.$parent.coins[title].name }} ({{ $parent.$parent.coins[title].symbol }}) mug is perfect for your morning coffee, afternoon tea, or whatever hot beverage you enjoy. It's glossy white and yields vivid prints that retain their quality when dish-washed and microwaved.
      <br><br>
      Material: Ceramic<br>
      Dimensions: height - 3.85" (9.8 cm), diameter - 3.35" (8.5 cm)<br><br>
      Dishwasher and microwave safe.<br>
      Made in China. Printed in the USA and Europe.<br>
    </div>

  </div>
</template>

<script>

export default {
  name: 'CryptoCoffeeMug',
  props: [ 'coin', 'available' ],
  data() {
    return {
      version: '',

      tshirtSize: 'L',
      hoodieSize: 'L',
      mugSize: '11oz',
      dressSize: 'M',
      dressAmount: 1,
      stickerAmount: 1,
      stickerSize: '4x4',
      tshirtAmount: 1,
      hoodieAmount: 1,
      mugAmount: 1,
      showAddedLinkTshirt: false,
      showAddedLinkDress: false,
      showAddedLinkHoodie: false,
      showAddedLinkMug: false,
      showAddedLinkSticker: false,
      dress2nd: false,
      sticker2nd: false,
    }
  },
  beforeMount() {
    this.title = this.coin;
    this.version = assetsVersion;
  },
  mounted() {

  },
  computed: {
    mugPrice() {
      return this.$parent.$parent.products['cc-mug'].sizesPrices[this.mugSize]
    },
  },
  methods: {

    addToCart: function (type) {

      if (type == 'cc-tshirt') {
        this.showAddedLinkTshirt = true;
      }
      else if (type == 'cc-dress') {
        this.showAddedLinkDress = true;
      }
      else if (type == 'cc-hoodie') {
        this.showAddedLinkHoodie = true;
      }
      else if (type == 'cc-mug') {
        this.showAddedLinkMug = true;
      }
      else if (type == 'cc-sticker') {
        this.showAddedLinkSticker = true;
      }

  
      // console.log('mug');
      let item_name = 'Mug';
      let item_name_full = this.$parent.$parent.coins[this.coin].name+' ('+this.$parent.$parent.coins[this.coin].symbol+') ' + item_name;
      let item_id = 'cc-mug-'+this.mugSize+'-'+this.$route.params.coinId;
      let item_price = this.mugPrice;
      let item_amount = this.mugAmount;
      let item_size = this.mugSize;

      dexie.products.put({item_id: item_id, item_name: item_name, item_name_full: item_name_full, item_size: item_size, item_price: item_price, item_amount: item_amount, coin_id: this.coin })
      .then((id) => {
        this.$parent.$parent.$emit('countRefresh');
      });
    },

    dressSecond() {
      this.dress2nd = true;
    },

    dressFirst() {
      this.dress2nd = false;
    },

  }
};
</script>