<template>
  <div v-cloak v-if="($route.name == 'CoinClothingSticker') || ($route.name == 'CoinClothing') || ($route.name == 'CryptoStickers')">

    <div class="grid-product">
      <div class="crypto-product" style="overflow:hidden;">            

        <div class="photo-slider">
          <div style="top:10px;" class="photo-slider-btn" v-bind:class="{ photoSliderBtnActive: !sticker2nd }" @click="sticker2nd = false"></div>
          <div style="top:40px;" class="photo-slider-btn" v-bind:class="{ photoSliderBtnActive: sticker2nd }" @click="sticker2nd = true"></div>
        </div>

        <router-link :to="'/merch/'+title+'/sticker'">
          <img v-show="!sticker2nd" :src="'/'+'merch/'+title+'-'+$parent.$parent.coins[title].symbol.toLowerCase()+'-sticker.jpg'+version" width="250" style="margin-top:10px;" draggable="false"  :alt="$parent.$parent.coins[title].name +' ('+ $parent.$parent.coins[title].symbol +') Crypto Sticker'">
          <img v-show="sticker2nd" :src="'/'+'merch/'+title+'-'+$parent.$parent.coins[title].symbol.toLowerCase()+'-sticker-laptop.jpg'+version" width="276" style="margin-left:-3px;margin-top:-4px;" draggable="false"  :alt="$parent.$parent.coins[title].name +' ('+ $parent.$parent.coins[title].symbol +') Crypto Sticker on a Laptop'">
        </router-link>

      </div>

      <div class="product-content">
        <router-link :to="'/merch/'+title+'/sticker'"><span class="product-title">{{ $parent.$parent.coins[title].name }} ({{ $parent.$parent.coins[title].symbol }}) Sticker</span></router-link>
        <br>
        <router-link :to="'/merch/'+title+'/sticker'" class="link-details link-hover">Kiss Cut Sticker</router-link>
        <br>
        <br>
        
        <span>
          Price: ${{stickerPrice}}
          <br>
          Size: 4x4 inch (10x10 cm)
          <br>
          <br>
          Quantity: <input type="number" v-model="stickerAmount" value="1" style="width:40px;">
          <div style="height:25px;"></div>

          <button v-if="available" @click="addToCart('cc-sticker')">Add to cart</button>
          <button v-else disabled>Out of Stock</button>
        
          <div v-if="showAddedLinkSticker" style="margin-top:10px;font-size:14px;">
            Added to <router-link to="/shopping-cart" class="underline">Shopping Cart</router-link>
          </div>
        </span>
      </div>
    </div>

    <div style="clear:both;padding-top:40px;" v-if="$route.name == 'CoinClothingSticker'">
      <b>Description</b>
      <br><br>
      These kiss cut stickers can be cut in any shape, while also leaving a wide enough border around the design for smooth peeling.
      <br>
      <br>
      - High opacity film that’s impossible to see through
      <br>
      - Fast and easy bubble-free application
      <br>
      - Durable vinyl, perfect for indoor and outdoor use
      <br>
      - 95µ density
      <br>
      <br>
      Don't forget to clean the surface before applying the sticker.
      <br>
      <br>
      This product is made on demand. No minimums.
    </div>

  </div>
</template>

<script>

export default {

  name: 'CryptoSticker',
  props: [ 'coin', 'available' ],
  data() {
    return {
      version: '',

      
      tshirtSize: 'L',
      hoodieSize: 'L',
      mugSize: '11oz',
      dressSize: 'M',
      dressAmount: 1,
      stickerAmount: 1,
      stickerSize: '4x4',
      tshirtAmount: 1,
      hoodieAmount: 1,
      mugAmount: 1,
      showAddedLinkTshirt: false,
      showAddedLinkDress: false,
      showAddedLinkHoodie: false,
      showAddedLinkMug: false,
      showAddedLinkSticker: false,
      dress2nd: false,
      sticker2nd: false,
    }
  },
  beforeMount() {
    this.title = this.coin;
    this.version = assetsVersion;
  },
  mounted() {
  },
  computed: {
    stickerPrice() {
      return this.$parent.$parent.products['cc-sticker'].sizesPrices[this.stickerSize]
    },
  },
  methods: {

    addToCart: function (type) {

      this.showAddedLinkSticker = true;

      // console.log('sti');
      let item_name = 'Sticker';
      let item_name_full = this.$parent.$parent.coins[this.coin].name+' ('+this.$parent.$parent.coins[this.coin].symbol+') ' + item_name;
      let item_id = 'cc-sticker-'+this.stickerSize+'-'+this.$route.params.coinId;
      let item_price = this.stickerPrice;
      let item_amount = this.stickerAmount;
      let item_size = this.stickerSize;

      dexie.products.put({item_id: item_id, item_name: item_name, item_name_full: item_name_full, item_size: item_size, item_price: item_price, item_amount: item_amount, coin_id: this.coin })
      .then((id) => {
        this.$parent.$emit('countRefresh');
      });
    },

    dressSecond() {
      this.dress2nd = true;
    },

    dressFirst() {
      this.dress2nd = false;
    },

  }
};
</script>