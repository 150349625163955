<template>
  <div v-cloak v-if="($route.name == 'CoinClothingTshirt') || ($route.name == 'CoinClothing') || ($route.name == 'CryptoTshirts')">

    <div class="grid-product">
    
      <div>
        <router-link :to="'/merch/'+title+'/t-shirt'">
          <div class="crypto-product">
            <img v-if="color == 'White'" :src="'/'+'merch/'+title+'-'+$parent.$parent.coins[title].symbol.toLowerCase()+'-t-shirt.jpg'+version" width="278" class="select-none" draggable="false"  :alt="$parent.$parent.coins[title].name +' ('+ $parent.$parent.coins[title].symbol +') Crypto T-Shirt White'">
            <img v-if="color == 'Black'" :src="'/'+'merch/'+title+'-'+$parent.$parent.coins[title].symbol.toLowerCase()+'-t-shirt-black.jpg'+version" width="278" class="select-none" draggable="false"  :alt="$parent.$parent.coins[title].name +' ('+ $parent.$parent.coins[title].symbol +') Crypto T-Shirt Black'">
          </div>
        </router-link>
      </div>

      <div class="product-content">
        <router-link :to="'/merch/'+title+'/t-shirt'"><span class="product-title">{{ $parent.$parent.coins[title].name }} ({{ $parent.$parent.coins[title].symbol }}) T-shirt</span></router-link>
        <br>
        <router-link :to="'/merch/'+title+'/t-shirt'" class="link-details link-hover">Gildan 64000 Unisex Softstyle</router-link>

        <div style="height:15px;"></div>

        <span>
          <div class="product-cell-left">Price</div>
          <div class="product-cell-left">${{tshirtPrice}}</div>

          <div style="height:16px;clear:both;"></div>

          <div class="product-cell-left">Size</div>
          <div class="product-cell-left">
            <select v-model="tshirtSize" style="width:65px;">
              <option v-for="(value, key) in $parent.$parent.products['cc-tshirt'].sizesPrices" :key="key" v-bind:value="key">
                {{ key }}
              </option>
            </select>
          </div>

          <div style="height:12px;clear:both;"></div>

          <div class="product-cell-left">Color</div>
          <div class="product-cell-left">
            <select v-model="color" style="width:65px;">
              <option v-for="(value, key) in $parent.$parent.products['cc-tshirt'].colors" :key="key" v-bind:value="key">
                {{ key }}
              </option>
            </select>
          </div>

          <div style="height:12px;clear:both;"></div>

          <div class="product-cell-left">Quantity</div>
          <div class="product-cell-left">
            <input v-model="tshirtAmount" type="number" value="1" style="padding-left:4px;width:59px;padding-top:2px;">
          </div>
          
          <div style="height:25px;clear:both;"></div>

          
          <button  v-if="available" @click="addToCart('cc-tshirt')">Add to cart</button>
          <button v-else disabled>Out of Stock</button>
          
          <div v-if="showAddedLinkTshirt" style="margin-top:10px;font-size:14px;position:absolute;">
            Added to <router-link to="/shopping-cart" class="underline">Shopping Cart</router-link>
          </div>
        </span>

      </div>

    </div>

    <div style="clear:both;padding-top:40px;" v-if="$route.name == 'CoinClothingTshirt'">
      <b>Description</b>
      <br>
      <br>
      For this {{ $parent.$parent.coins[title].name }} ({{ $parent.$parent.coins[title].symbol }}) t-shirt, the Gildan 64000 model is a great choice. It's a classic t-shirt that compliments any outfit. It's made of a heavier cotton and the double-stitched neckline and sleeves give it more durability, so it can become an everyday favorite.
      <br><br>
      100% ring-spun cotton<br>
      Fabric weight: 4.5 oz/y² (153 g/m²)<br>
      Pre-shrunk for extra durability<br>
      Shoulder-to-shoulder taping<br>
      Quarter-turned to avoid crease down the center<br>
      <br>
      The male model in the above photo is wearing a size M. He's 6.2 feet (190 cm) tall, chest circumference 37.7" (96 cm), waist circumference 33.4" (85 cm). 
      <div style="height:60px;"></div>
      <b>Size guide</b>
      <br><br>
      <div>
      US sizes (In)<div id="size-guide-12" class="wysiwyg">
      <div class="size-guide"><table class="size-guide__table"><thead><tr><td></td><td>S</td><td>M</td><td>L</td><td>XL</td><td>2XL</td><td>3XL</td></tr></thead><tbody><tr><td class="size-guide__table-first-row"><span>Length</span></td><td>28</td><td>29</td><td>30</td><td>31</td><td>32</td><td>33</td></tr><tr><td class="size-guide__table-first-row"><span>Width</span></td><td>18</td><td>20</td><td>22</td><td>24</td><td>26</td><td>28</td></tr></tbody></table><div class="margin-top-15"></div></div></div>
      </div>
      <br>
      <div>
      Metric sizes (Cm)<div id="size-guide-12" class="wysiwyg">
      <div class="size-guide"><table class="size-guide__table"><thead><tr><td></td><td>S</td><td>M</td><td>L</td><td>XL</td><td>2XL</td><td>3XL</td></tr></thead><tbody><tr><td class="size-guide__table-first-row"><span>Length</span></td><td>71.1</td><td>73.7</td><td>76.2</td><td>78.7</td><td>81.3</td><td>83.8</td></tr><tr><td class="size-guide__table-first-row"><span>Width</span></td><td>45.7</td><td>50.8</td><td>55.9</td><td>61</td><td>66</td><td>71.1</td></tr></tbody></table><div class="margin-top-15"></div></div></div>
      <br>
      Measurements are provided by suppliers.
      <br>
      Product measurements may vary by up to 2" (5 cm).
      </div>
      <div style="clear:both;height:40px;"></div>
      <b>Care instructions</b>
      <br>
      <br>      
      - Machine wash cold, inside-out, gentle cycle with mild detergent and similar colors. Use non-chlorine bleach, only when necessary. No fabric softeners.<br>
      - Tumble dry low, or hang-dry for longest life.<br>
      - Cool iron inside-out if necessary. Do not iron decoration.<br>
      - Do not dry clean.
    </div>

  </div>
</template>

<script>

export default {
  name: 'CryptoTshirt',
  props: [ 'coin', 'available' ],
  data() {
    return {
      version: '',

      tshirtSize: 'L',
      hoodieSize: 'L',
      color: 'White',
      mugSize: '11oz',
      dressSize: 'M',
      dressAmount: 1,
      stickerAmount: 1,
      stickerSize: '4x4',
      tshirtAmount: 1,
      hoodieAmount: 1,
      mugAmount: 1,
      showAddedLinkTshirt: false,
      showAddedLinkDress: false,
      showAddedLinkHoodie: false,
      showAddedLinkMug: false,
      showAddedLinkSticker: false,
      dress2nd: false,
      sticker2nd: false,

      cc_coins_home: cc_coins,
    }
  },
  beforeMount() {
    this.title = this.coin;
    this.version = assetsVersion;
  },
  mounted() {
    // console.log(this.cc_coins_home);
    
    // loop through this.cc_coins_home
    for (var i = 0; i < this.cc_coins_home.length; i++) {
      console.log(this.cc_coins_home[i]);
    }
  },
  computed: {
    tshirtPrice() {
      return this.$parent.$parent.products['cc-tshirt'].sizesPrices[this.tshirtSize]
    },
  },
  methods: {

    addToCart: function (type) {

      this.showAddedLinkTshirt = true;

      // console.log('tee');
      let item_name = 'T-shirt';
      let item_name_full = this.$parent.$parent.coins[this.coin].name+' ('+this.$parent.$parent.coins[this.coin].symbol+') ' + item_name;
      let item_id = 'cc-tshirt-'+this.tshirtSize+'-'+this.$route.params.coinId+'-'+this.color;
      let item_price = this.tshirtPrice;
      let item_amount = this.tshirtAmount;
      let item_size = this.tshirtSize;
      let item_config = { color: this.color };

      dexie.products.put({item_id: item_id, item_name: item_name, item_name_full: item_name_full, item_size: item_size, item_price: item_price, item_amount: item_amount, item_config: item_config, coin_id: this.coin })
      .then((id) => {
        this.$parent.$emit('countRefresh');
      });
    },

    dressSecond() {
      this.dress2nd = true;
    },

    dressFirst() {
      this.dress2nd = false;
    },

  }
};
</script>

<style>
  .product-cell-left {
    width: 70px;
    text-align: left;
    float:left;
  }
  /* .product-cell-right  */

</style>