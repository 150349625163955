<template>
  <div v-cloak v-if="($route.name == 'CoinClothingHoodie') || ($route.name == 'CoinClothing') || ($route.name == 'CryptoHoodies')">
    
    <div class="grid-product">
      <div>
        <router-link :to="'/merch/'+title+'/hoodie'">
          <div class="crypto-product">
            <img v-if="color == 'White'" :src="'/'+'merch/'+title+'-'+$parent.$parent.coins[title].symbol.toLowerCase()+'-hoodie.jpg'+version" width="274" class="select-none" draggable="false"  :alt="$parent.$parent.coins[title].name +' ('+ $parent.$parent.coins[title].symbol +') Crypto Hoodie White'">
            <img v-if="color == 'Black'" :src="'/'+'merch/'+title+'-'+$parent.$parent.coins[title].symbol.toLowerCase()+'-hoodie-black.jpg'+version" width="274" class="select-none" draggable="false"  :alt="$parent.$parent.coins[title].name +' ('+ $parent.$parent.coins[title].symbol +') Crypto Hoodie Black'">
            <!-- <div class="coin-hoodie"><div class="coin-hoodie-inner"><img class="coin-hoodie-img select-none" draggable="false" :src="'/'+'crypto-logos/pub/thumbs/'+title+'.png'"></div></div> -->
          </div>
        </router-link>
      </div>

      <div class="product-content">
        <router-link :to="'/merch/'+title+'/hoodie'">
          <span class="product-title">{{ $parent.$parent.coins[title].name }} ({{ $parent.$parent.coins[title].symbol }}) Hoodie</span>
        </router-link>
        <br>
        <router-link :to="'/merch/'+title+'/hoodie'" class="link-details link-hover">Gildan 18500 Unisex Heavy Blend</router-link>
        <div style="height:15px;"></div>

        <span>
          <div class="product-cell-left">Price</div>
          <div class="product-cell-left">${{hoodiePrice}}</div>

          <div style="height:16px;clear:both;"></div>

          <div class="product-cell-left">Size</div>
          <div class="product-cell-left">
            <select v-model="hoodieSize" style="width:65px;">
              <option v-for="(value, key) in $parent.$parent.products['cc-hoodie'].sizesPrices" :key="key" v-bind:value="key">
                {{ key }}
              </option>
            </select>
          </div>

          <div style="height:12px;clear:both;"></div>

          <div class="product-cell-left">Color</div>
          <div class="product-cell-left">
            <select v-model="color" style="width:65px;">
              <option v-for="(value, key) in $parent.$parent.products['cc-hoodie'].colors" :key="key" v-bind:value="key">
                {{ key }}
              </option>
            </select>
          </div>

          <div style="height:12px;clear:both;"></div>

          <div class="product-cell-left">Quantity</div>
          <div class="product-cell-left">
            <input v-model="hoodieAmount" type="number" value="1" style="padding-left:4px;width:59px;padding-top:2px;">
          </div>

          <div style="height:25px;clear:both;"></div>


          <button  v-if="available" @click="addToCart('cc-hoodie')">Add to cart</button>
          <button v-else disabled>Out of Stock</button>

          <div v-if="showAddedLinkHoodie" style="margin-top:10px;font-size:14px;position:absolute;">
            Added to <router-link to="/shopping-cart" class="underline">Shopping Cart</router-link>
          </div>
        </span>
      </div>

    </div>

    <div style="clear:both;padding-top:40px;" v-if="$route.name == 'CoinClothingHoodie'">
      <b>Description</b>
      <br><br>
      This is your favorite hooded {{ $parent.$parent.coins[title].name }} ({{ $parent.$parent.coins[title].symbol }}) sweatshirt, it's soft, smooth and stylish. Perfect for cooler evenings.
      <br><br>
      8.0 oz. fabric weight
      <br>
      50/50 cotton/polyester
      <br>
      Reduced pilling and softer air-jet spun yarn
      <br>
      Double-lined hood with matching drawcord
      <br>
      1x1 athletic rib knit cuffs and waistband with spandex
      <br>
      Double-needle stitching throughout
      <br>
      Front pouch pocket
      <div style="height:60px;"></div>
      <b>Size guide</b>
      <br><br>
      <div>
      US sizes (In)<div id="size-guide-12" class="wysiwyg">
      <div class="size-guide"><table class="size-guide__table"><thead><tr><td></td><td>S</td><td>M</td><td>L</td><td>XL</td><td>2XL</td><td>3XL</td><td>4XL</td><td>5XL</td></tr></thead><tbody><tr><td class="size-guide__table-first-row"><span>Length</span></td><td>27</td><td>28</td><td>29</td><td>30</td><td>31</td><td>32</td><td>33</td><td>34</td></tr><tr><td class="size-guide__table-first-row"><span>Width</span></td><td>20</td><td>22</td><td>24</td><td>26</td><td>28</td><td>30</td><td>32</td><td>34</td></tr></tbody></table><div class="margin-top-15"></div></div>
      </div></div>
      <br>
      <div>
      Metric sizes (Cm)<div id="size-guide-12" class="wysiwyg">
      <div class="size-guide"><table class="size-guide__table"><thead><tr><td></td><td>S</td><td>M</td><td>L</td><td>XL</td><td>2XL</td><td>3XL</td><td>4XL</td><td>5XL</td></tr></thead><tbody><tr><td class="size-guide__table-first-row"><span>Length</span></td><td>68.6</td><td>71.1</td><td>73.7</td><td>76.2</td><td>78.7</td><td>81.3</td><td>83.8</td><td>86.4</td></tr><tr><td class="size-guide__table-first-row"><span>Width</span></td><td>50.8</td><td>55.9</td><td>61</td><td>66</td><td>71.1</td><td>76.2</td><td>81.3</td><td>86.4</td></tr></tbody></table><div class="margin-top-15"></div></div>
      </div></div>
      <br>
      Measurements are provided by suppliers.
      <br>
      Product measurements may vary by up to 2" (5 cm).
      <div style="clear:both;height:50px;"></div>
      <b>Care instructions</b>
      <br>
      <br>      
      - Machine wash cold, inside-out, gentle cycle with mild detergent and similar colors. Use non-chlorine bleach, only when necessary. No fabric softeners.<br>
      - Tumble dry low, or hang-dry for longest life.<br>
      - Cool iron inside-out if necessary. Do not iron decoration.<br>
      - Do not dry clean.<br>
    </div>

  </div>
</template>

<script>

export default {
  name: 'CryptoHoodie',
  props: [ 'coin', 'available' ],
  data() {
    return {
      version: '',

      tshirtSize: 'L',
      hoodieSize: 'L',
      color: 'White',
      mugSize: '11oz',
      dressSize: 'M',
      dressAmount: 1,
      stickerAmount: 1,
      stickerSize: '4x4',
      tshirtAmount: 1,
      hoodieAmount: 1,
      mugAmount: 1,
      showAddedLinkTshirt: false,
      showAddedLinkDress: false,
      showAddedLinkHoodie: false,
      showAddedLinkMug: false,
      showAddedLinkSticker: false,
      dress2nd: false,
      sticker2nd: false,
    }
  },
  beforeMount() {
    this.title = this.coin;
    this.version = assetsVersion;
  },
  mounted() {
  },
  computed: {
    hoodiePrice() {
      return this.$parent.$parent.products['cc-hoodie'].sizesPrices[this.hoodieSize]
    },
  },
  methods: {

    addToCart: function () {

      this.showAddedLinkHoodie = true;

      // console.log('hoo');
      let item_name = 'Hoodie';
      let item_name_full = this.$parent.$parent.coins[this.coin].name+' ('+this.$parent.$parent.coins[this.coin].symbol+') ' + item_name;
      let item_id = 'cc-hoodie-'+this.hoodieSize+'-'+this.$route.params.coinId+'-'+this.color;
      let item_price = this.hoodiePrice;
      let item_amount = this.hoodieAmount;
      let item_size = this.hoodieSize;
      let item_config = { color: this.color };


      dexie.products.put({item_id: item_id, item_name: item_name, item_name_full: item_name_full, item_size: item_size, item_price: item_price, item_amount: item_amount, item_config: item_config, coin_id: this.coin })
      .then((id) => {
        this.$parent.$emit('countRefresh');
      });
    },

    dressSecond() {
      this.dress2nd = true;
    },

    dressFirst() {
      this.dress2nd = false;
    },

  }
};
</script>