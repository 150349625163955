<template>
  <div v-cloak v-if="$route.name == 'CryptoFashion'" >
    
    <h3>Crypto Fashion</h3>
    <p>
      Crypto Fashion is a new crypto merchandise line, started in early 2021.
    </p>
    <div style="height:40px;clear:both;"></div>


    <!-- POSTER -->
    <h3>Crypto Logos Poster 2021</h3>
    <div style="height:30px;clear:both;"></div>

    <SingleCryptoPoster  @countRefresh="countRefresh" />

    <div style="height:70px;"></div>


    <!-- CUSTOM DESIGNS -->

    <h3>Custom Designs</h3>

    <div style="height:30px;"></div>

    <single-apocalypse-wow  @countRefresh="countRefresh"/>

    <div style="height:70px;"></div>


    <!-- HODL DESIGNS -->
    
    <h3>HODL T-Shirts</h3>

    <p>
      A classic t-shirt that compliments any outfit. It's made of a heavier cotton and the double-stitched neckline and sleeves give it more durability, so it can become an everyday favorite.
    </p>
    
    <div v-for="(coin, index) in cryptoHodlTshirts" :key="index" style="margin-top:-20px;">
      <div style="height:65px;clear:both;"></div>
      <CryptoHodlTshirt :coin="coin" :index="index" />
    </div>


    <div style="clear:both;height:80px;"></div>
    <div style="border-bottom:1px solid #b1b1b1;"></div>
    <div style="clear:both;height:20px;"></div>

    <div v-if="$route.name == 'CryptoToteBag' || $route.name == 'CryptoLaptopSleeve' || $route.name == 'CryptoLogosPoster'">
      See more: <router-link to="/crypto-fashion" class="underline">Crypto Fashion</router-link>
    </div>
    <div v-else>
      See more: <router-link to="/" class="underline">All Cryptocurrency Clothing</router-link>
    </div>

  </div>
</template>

<script>
import CryptoHodlTshirt from "./CryptoHodlTshirt.vue";

import SingleCryptoPoster from "./SingleCryptoPoster.vue";
import SingleApocalypseWow from "./SingleApocalypseWow.vue";

export default {

  name: 'CryptoFashion',
  props: [ 'coin' ],
  components: {
    CryptoHodlTshirt,
    SingleCryptoPoster,
    SingleApocalypseWow
  },

  data() {
    return {
      cryptoHodlTshirts: [ 'bitcoin', 'ethereum', 'xrp', 'litecoin', 'stellar', 'monero', 'uniswap', 'yearn-finance' ],
      version: '',
    }
  },
  beforeMount() {
    // this.title = this.coin;
    this.version = assetsVersion;
  },

  mounted() {
    // console.log(this.$route.name);
  },

  methods: {
    countRefresh() {
      this.$emit('countRefresh');
    }
  }
};
</script>