<template>
    <div>
        <div v-if="coinId == 'bitcoin'">

            <h3 v-if="$route.name == 'CryptoLogo'">{{ $parent.$parent.coins[coinId].name }} Logo History</h3>
            <h3 v-else >{{ $parent.$parent.coins[coinId].name }} ({{ $parent.$parent.coins[coinId].symbol }})</h3>

          <div style="height:20px;"></div>

          <div class="flex-grid">

            <div class="col grid-border">
              <div class="history-grid-container">

                <div class="div-middle-outer">
                  <div class="div-middle-inner">
                    <img :src="'/logos/history/bitcoin-logo-2010-v1.png?v=003'" class="history-img" :alt="$parent.$parent.coins[coinId].name +'('+ $parent.$parent.coins[coinId].symbol +') old logo 210'">
                  </div>
                </div>
                
                <div class="div-middle-text history-logo">
                  <b>2010</b>
                  <br>
                  <a href="https://bitcointalk.org/index.php?topic=64.0" target="_blank">
                    <u>Satoshi</u>
                  </a>
                </div>
              </div>
            </div>
            
            <div class="col grid-border">
              <div class="history-grid-container">

                <div class="div-middle-outer">
                  <div class="div-middle-inner">
                    <img :src="'/logos/history/bitcoin-logo-2010-v2.png?v=003'" class="history-img" :alt="$parent.$parent.coins[coinId].name +'('+ $parent.$parent.coins[coinId].symbol +') old logo 2010'">
                  </div>
                </div>
                
                <div class="div-middle-text history-logo">
                  <b>2010</b>
                  <br>
                  <a href="https://www.coindesk.com/about-that-orange-b-the-history-of-bitcoins-logos" target="_blank">
                    <u>Satoshi</u>
                  </a>
                </div>

              </div>
            </div>
            
            <div class="col grid-border">
              <div class="history-grid-container">

                <div class="div-middle-outer">
                  <div class="div-middle-inner">
                    <img :src="'/'+'logos/'+coinId+'-'+$parent.$parent.coins[coinId].symbol.toLowerCase()+'-logo.svg?v=003'" class="history-img" >
                  </div>
                </div>
                
                <div class="div-middle-text history-logo">
                  <b>2011</b>
                  <br>
                  <a href="https://bitcointalk.org/index.php?topic=1631.0" target="_blank">
                    <u>Bitboy</u>
                  </a>
                </div>

              </div>
            </div>
            
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
          </div>

        </div>

        
        <div v-if="coinId == 'litecoin'">

            <h3 v-if="$route.name == 'CryptoLogo'">{{ $parent.$parent.coins[coinId].name }} Logo History</h3>
            <h3 v-else >{{ $parent.$parent.coins[coinId].name }} ({{ $parent.$parent.coins[coinId].symbol }})</h3>

          <div style="height:20px;"></div>

          <div class="flex-grid">

            <div class="col grid-border">
              <div class="history-grid-container">

                <div class="div-middle-outer">
                  <div class="div-middle-inner">
                    <img :src="'/logos/history/litecoin-logo-2011.png?v=001'" class="history-img" :alt="$parent.$parent.coins[coinId].name +'('+ $parent.$parent.coins[coinId].symbol +') old logo 2011'">
                  </div>
                </div>
                
                <div class="div-middle-text history-logo">
                  <b>2011</b>
                  <br>
                  <a href="https://bitcointalk.org/index.php?topic=49563.0" target="_blank">
                    <u>Info</u>
                  </a>

                </div>
              </div>
            </div>
            
            <div class="col grid-border">
              <div class="history-grid-container">

                <div class="div-middle-outer">
                  <div class="div-middle-inner">
                    <img :src="'/logos/history/litecoin-logo-2013.png?v=001'" class="history-img" :alt="$parent.$parent.coins[coinId].name +'('+ $parent.$parent.coins[coinId].symbol +') old logo 2013'">
                  </div>
                </div>
                
                <div class="div-middle-text history-logo">
                  <b>2013</b>
                  <br>
                  <a href="https://bitcointalk.org/index.php?topic=140642.0" target="_blank">
                    <u>Info</u>
                  </a>
                </div>

              </div>
            </div>
            
            <div class="col grid-border">
              <div class="history-grid-container">

                <div class="div-middle-outer">
                  <div class="div-middle-inner">
                    <img :src="'/'+'logos/'+coinId+'-'+$parent.$parent.coins[coinId].symbol.toLowerCase()+'-logo.svg?v=003'" class="history-img" >
                  </div>
                </div>
                
                <div class="div-middle-text history-logo">
                  <b>2019</b>
                  <br>
                  <a href="https://medium.com/@LitecoinDotCom/litecoin-community-moves-to-adopt-new-blue-logo-d1c4209c6911" target="_blank">
                    <u>Info</u>
                  </a>
                </div>

              </div>
            </div>
            
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
          </div>

        </div>


        
        <div v-if="coinId == 'xrp'">

            <h3 v-if="$route.name == 'CryptoLogo'">{{ $parent.$parent.coins[coinId].name }} Logo History</h3>
            <h3 v-else >{{ $parent.$parent.coins[coinId].name }} ({{ $parent.$parent.coins[coinId].symbol }})</h3>

          <div style="height:20px;"></div>

          <div class="flex-grid">

            <div class="col grid-border">
              <div class="history-grid-container">

                <div class="div-middle-outer">
                  <div class="div-middle-inner">
                    <img :src="'/logos/history/xrp-logo-2012.png?v=003'" class="history-img" :alt="$parent.$parent.coins[coinId].name +'('+ $parent.$parent.coins[coinId].symbol +') 2012 logo'">
                  </div>
                </div>
                
                <div class="div-middle-text history-logo">
                  <b>2012</b>
                  <br>

                </div>
              </div>
            </div>
            
            <div class="col grid-border">
              <div class="history-grid-container">

                <div class="div-middle-outer">
                  <div class="div-middle-inner">
                    <img :src="'/'+'logos/'+coinId+'-'+$parent.$parent.coins[coinId].symbol.toLowerCase()+'-logo.svg?v=003'" class="history-img">
                  </div>
                </div>
                
                <div class="div-middle-text history-logo">
                  <b>2018</b>
                  <br>
                  <a href="https://bitcoinexchangeguide.com/ripple-xrp-debuts-new-logo-symbol-design-community-reacts/" target="_blank">
                    <u>Info</u>
                  </a>
                </div>

              </div>
            </div>
            
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
          </div>

        </div>


                
        <div v-if="coinId == 'stellar'">

            <h3 v-if="$route.name == 'CryptoLogo'">{{ $parent.$parent.coins[coinId].name }} Logo History</h3>
            <h3 v-else >{{ $parent.$parent.coins[coinId].name }} ({{ $parent.$parent.coins[coinId].symbol }})</h3>

          <div style="height:20px;"></div>

          <div class="flex-grid">

            <div class="col grid-border">
              <div class="history-grid-container">

                <div class="div-middle-outer">
                  <div class="div-middle-inner">
                    <img :src="'/logos/history/stellar-logo-2014.svg?v=003'" class="history-img" :alt="$parent.$parent.coins[coinId].name +'('+ $parent.$parent.coins[coinId].symbol +') old 2014 logo'">
                  </div>
                </div>
                
                <div class="div-middle-text history-logo">
                  <b>2014</b>
                  <br>

                </div>
              </div>
            </div>
            
            <div class="col grid-border">
              <div class="history-grid-container">

                <div class="div-middle-outer">
                  <div class="div-middle-inner">
                    <img :src="'/'+'logos/'+coinId+'-'+$parent.$parent.coins[coinId].symbol.toLowerCase()+'-logo.svg?v=003'" class="history-img">
                  </div>
                </div>
                
                <div class="div-middle-text history-logo">
                  <b>2019</b>
                  <br>
                  <a href="https://www.stellar.org/blog/announcing-the-new-stellar-logo" target="_blank">
                    <u>Info</u>
                  </a>
                </div>

              </div>
            </div>
            
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
          </div>

        </div>


        <div v-if="coinId == 'decentraland'">

            <h3 v-if="$route.name == 'CryptoLogo'">{{ $parent.$parent.coins[coinId].name }} Logo History</h3>
            <h3 v-else >{{ $parent.$parent.coins[coinId].name }} ({{ $parent.$parent.coins[coinId].symbol }})</h3>

          <div style="height:20px;"></div>

          <div class="flex-grid">

            <div class="col grid-border">
              <div class="history-grid-container">

                <div class="div-middle-outer">
                  <div class="div-middle-inner">
                    <img :src="'/logos/history/decentraland-logo-2017.png?v=001'" class="history-img" :alt="$parent.$parent.coins[coinId].name +'('+ $parent.$parent.coins[coinId].symbol +') old logo 2017'">
                  </div>
                </div>
                
                <div class="div-middle-text history-logo">
                  <b>2017</b>
                  <br>
                </div>
              </div>
            </div>
            
            <div class="col grid-border">
              <div class="history-grid-container">

                <div class="div-middle-outer">
                  <div class="div-middle-inner">
                    <img :src="'/'+'logos/'+coinId+'-'+$parent.$parent.coins[coinId].symbol.toLowerCase()+'-logo.svg?v=001'" class="history-img">
                  </div>
                </div>
                
                <div class="div-middle-text history-logo">
                  <b>2019</b>
                  <br>
                  <a href="https://decentraland.org/blog/announcements/decentralands-fresh-new-look/" target="_blank">
                    <u>Info</u>
                  </a>
                </div>

              </div>
            </div>
            
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
          </div>

        </div>

        <div v-if="coinId == 'komodo'">

            <h3 v-if="$route.name == 'CryptoLogo'">{{ $parent.$parent.coins[coinId].name }} Logo History</h3>
            <h3 v-else >{{ $parent.$parent.coins[coinId].name }} ({{ $parent.$parent.coins[coinId].symbol }})</h3>

          <div style="height:20px;"></div>

          <div class="flex-grid">

            <div class="col grid-border">
              <div class="history-grid-container">

                <div class="div-middle-outer">
                  <div class="div-middle-inner">
                    <img :src="'/logos/history/komodo-logo-2016.png?v=001'" class="history-img" :alt="$parent.$parent.coins[coinId].name +'('+ $parent.$parent.coins[coinId].symbol +') old logo 2016'">
                  </div>
                </div>
                
                <div class="div-middle-text history-logo">
                  <b>2016</b>
                  <br>
                </div>
              </div>
            </div>
            
            <div class="col grid-border">
              <div class="history-grid-container">

                <div class="div-middle-outer">
                  <div class="div-middle-inner">
                    <img :src="'/'+'logos/'+coinId+'-'+$parent.$parent.coins[coinId].symbol.toLowerCase()+'-logo.svg?v=001'" class="history-img">
                  </div>
                </div>
                
                <div class="div-middle-text history-logo">
                  <b>2019</b>
                  <br>
                  <a href="https://cryptovest.com/news/komodos-kmd-new-branding-revealed-along-with-antara-protocol/" target="_blank">
                    <u>Info</u>
                  </a>
                </div>

              </div>
            </div>
            
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
          </div>

        </div>


        <div v-if="coinId == 'bitcoin-sv'">

            <h3 v-if="$route.name == 'CryptoLogo'">{{ $parent.$parent.coins[coinId].name }} Logo History</h3>
            <h3 v-else >{{ $parent.$parent.coins[coinId].name }} ({{ $parent.$parent.coins[coinId].symbol }})</h3>

          <div style="height:20px;"></div>

          <div class="flex-grid">

            <div class="col grid-border">
              <div class="history-grid-container">

                <div class="div-middle-outer">
                  <div class="div-middle-inner">
                    <img :src="'/logos/history/bitcoin-sv-logo-2018.png?v=001'" class="history-img" :alt="$parent.$parent.coins[coinId].name +'('+ $parent.$parent.coins[coinId].symbol +') old logo 2018'">
                  </div>
                </div>
                
                <div class="div-middle-text history-logo">
                  <b>2018</b>
                  <br>
                </div>
              </div>
            </div>
            
            <div class="col grid-border">
              <div class="history-grid-container">

                <div class="div-middle-outer">
                  <div class="div-middle-inner">
                    <img :src="'/'+'logos/'+coinId+'-'+$parent.$parent.coins[coinId].symbol.toLowerCase()+'-logo.svg?v=001'" class="history-img">
                  </div>
                </div>
                
                <div class="div-middle-text history-logo">
                  <b>2019</b>
                  <br>
                  <a href="https://www.prnewswire.com/news-releases/bitcoin-sv-bsv-logo-unveiled-for-rebirth-of-original-bitcoin-889141625.html" target="_blank">
                    <u>Info</u>
                  </a>
                </div>

              </div>
            </div>
            
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
          </div>

        </div>


        <div v-if="coinId == 'loopring'">

            <h3 v-if="$route.name == 'CryptoLogo'">{{ $parent.$parent.coins[coinId].name }} Logo History</h3>
            <h3 v-else >{{ $parent.$parent.coins[coinId].name }} ({{ $parent.$parent.coins[coinId].symbol }})</h3>

          <div style="height:20px;"></div>

          <div class="flex-grid">

            <div class="col grid-border">
              <div class="history-grid-container">

                <div class="div-middle-outer">
                  <div class="div-middle-inner">
                    <img :src="'/logos/history/loopring-lrc-logo-2017.svg?v=001'" class="history-img" :alt="$parent.$parent.coins[coinId].name +'('+ $parent.$parent.coins[coinId].symbol +') old Loopring logo 2017'">
                  </div>
                </div>
                
                <div class="div-middle-text history-logo">
                  <b>2017</b>
                  <br>
                  <a href="https://twitter.com/loopringorg/status/931903508172951553?lang=en" target="_blank">
                    <u>Info</u>
                  </a>
                </div>
              </div>
            </div>
            
            <div class="col grid-border">
              <div class="history-grid-container">

                <div class="div-middle-outer">
                  <div class="div-middle-inner">
                    <img :src="'/'+'logos/'+coinId+'-'+$parent.$parent.coins[coinId].symbol.toLowerCase()+'-logo.svg?v=001'" class="history-img" style="width:75%;">
                  </div>
                </div>
                
                <div class="div-middle-text history-logo">
                  <b>2018</b>
                  <br>
                  <a href="https://www.reddit.com/r/loopringorg/comments/7tbq86/this_is_our_new_logo_for_the_loopring_protocol/" target="_blank">
                    <u>Info</u>
                  </a>
                </div>

              </div>
            </div>
            
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
          </div>

        </div>


        <div v-if="coinId == 'polkadot-new'">

          <h3 v-if="$route.name == 'CryptoLogo'">{{ $parent.$parent.coins[coinId].name }} Logo History</h3>
          <h3 v-else >{{ $parent.$parent.coins[coinId].name }} ({{ $parent.$parent.coins[coinId].symbol }})</h3>

          <div style="height:20px;"></div>

          <div class="flex-grid">

            <div class="col grid-border">
              <div class="history-grid-container">

                <div class="div-middle-outer">
                  <div class="div-middle-inner">
                    <img :src="'/logos/history/polkadot-new-dot-logo-2020.svg?v=001'" class="history-img" :alt="$parent.$parent.coins[coinId].name +'('+ $parent.$parent.coins[coinId].symbol +') old Polkadot logo 2020'">
                  </div>
                </div>
                
                <div class="div-middle-text history-logo">
                  <b>2020</b>
                </div>
              </div>
            </div>
            
            <div class="col grid-border">
              <div class="history-grid-container">

                <div class="div-middle-outer">
                  <div class="div-middle-inner">
                    <img :src="'/'+'logos/'+coinId+'-'+$parent.$parent.coins[coinId].symbol.toLowerCase()+'-logo.svg?v=001'" class="history-img" style="width:75%;">
                  </div>
                </div>
                
                <div class="div-middle-text history-logo">
                  <b>2021</b>
                  <br>
                  <a href="https://vote.polkadot.network/#vote-results" target="_blank">
                    <u>Info</u>
                  </a>
                </div>

              </div>
            </div>
            
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
          </div>

        </div>


        <div v-if="coinId == 'augur'">

            <h3 v-if="$route.name == 'CryptoLogo'">{{ $parent.$parent.coins[coinId].name }} Logo History</h3>
            <h3 v-else >{{ $parent.$parent.coins[coinId].name }} ({{ $parent.$parent.coins[coinId].symbol }})</h3>

          <div style="height:20px;"></div>

          <div class="flex-grid">

            <div class="col grid-border">
              <div class="history-grid-container">

                <div class="div-middle-outer">
                  <div class="div-middle-inner">
                    <img :src="'/logos/history/augur-rep-logo-2015.svg?v=002'" class="history-img" :alt="$parent.$parent.coins[coinId].name +'('+ $parent.$parent.coins[coinId].symbol +') old Augur logo 2015'">
                  </div>
                </div>
                
                <div class="div-middle-text history-logo">
                  <b>2015</b>
                  <br>
                  REPv1
                </div>
              </div>
            </div>
            
            <div class="col grid-border">
              <div class="history-grid-container">

                <div class="div-middle-outer">
                  <div class="div-middle-inner">
                    <img :src="'/'+'logos/'+coinId+'-'+$parent.$parent.coins[coinId].symbol.toLowerCase()+'-logo.svg?v=001'" class="history-img" style="width:75%;">
                  </div>
                </div>
                
                <div class="div-middle-text history-logo">
                  <b>2020</b>
                  <br>
                  REPv2
                </div>

              </div>
            </div>
            
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
          </div>

        </div>
        
        <div v-if="coinId == 'nano'">

            <h3 v-if="$route.name == 'CryptoLogo'">{{ $parent.$parent.coins[coinId].name }} Logo History</h3>
            <h3 v-else >{{ $parent.$parent.coins[coinId].name }} ({{ $parent.$parent.coins[coinId].symbol }})</h3>

          <div style="height:20px;"></div>

          <div class="flex-grid">

            <div class="col grid-border">
              <div class="history-grid-container">

                <div class="div-middle-outer">
                  <div class="div-middle-inner">
                    <img :src="'/logos/history/nano-raiblocks-logo-2016.png?v=001'" class="history-img" :alt="$parent.$parent.coins[coinId].name +'('+ $parent.$parent.coins[coinId].symbol +') old RaiBlocks logo 2016'">
                  </div>
                </div>
                
                <div class="div-middle-text history-logo">
                  <b>2016</b>
                  <br>
                </div>
              </div>
            </div>
            
            <div class="col grid-border">
              <div class="history-grid-container">

                <div class="div-middle-outer">
                  <div class="div-middle-inner">
                    <img :src="'/logos/history/nano-2018-2021.svg?v=001'" class="history-img" style="width:75%;">
                  </div>
                </div>
                
                <div class="div-middle-text history-logo">
                  <b>2018</b>
                  <br>
                  <a href="https://www.forbes.com/sites/jessedamiani/2018/01/31/raiblocks-rebrands-to-nano-xrb-price-has-surged-41-in-24-hours-with-20-bull-run-this-morning/#3c9180211567" target="_blank">
                    <u>Info</u>
                  </a>
                </div>

              </div>
            </div>
            
            <div class="col grid-border">
              <div class="history-grid-container">

                <div class="div-middle-outer">
                  <div class="div-middle-inner">
                    <img :src="'/'+'logos/'+coinId+'-'+$parent.$parent.coins[coinId].symbol.toLowerCase()+'-logo.svg?v=001'" class="history-img" style="width:75%;">
                  </div>
                </div>
                
                <div class="div-middle-text history-logo">
                  <b>2021</b>
                  <br>
                  <a href="https://blog.nano.org/say-hello-to-xno-76568e1b76a0" target="_blank">
                    <u>Info</u>
                  </a>
                </div>

              </div>
            </div>
            
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
          </div>

        </div>


        
        <div v-if="coinId == 'chainlink'">

            <h3 v-if="$route.name == 'CryptoLogo'">{{ $parent.$parent.coins[coinId].name }} Logo History</h3>
            <h3 v-else >{{ $parent.$parent.coins[coinId].name }} ({{ $parent.$parent.coins[coinId].symbol }})</h3>

          <div style="height:20px;"></div>

          <div class="flex-grid">

            <div class="col grid-border">
              <div class="history-grid-container">

                <div class="div-middle-outer">
                  <div class="div-middle-inner">
                    <img :src="'/logos/history/chainlink-logo-2017.svg?v=001'" class="history-img" :alt="$parent.$parent.coins[coinId].name +'('+ $parent.$parent.coins[coinId].symbol +') old logo 2017'">
                  </div>
                </div>
                
                <div class="div-middle-text history-logo">
                  <b>2017</b>
                  <br>
                </div>
              </div>
            </div>
            
            <div class="col grid-border">
              <div class="history-grid-container">

                <div class="div-middle-outer">
                  <div class="div-middle-inner">
                    <img :src="'/'+'logos/'+coinId+'-'+$parent.$parent.coins[coinId].symbol.toLowerCase()+'-logo.svg?v=001'" class="history-img" style="width:75%;">
                  </div>
                </div>
                
                <div class="div-middle-text history-logo">
                  <b>2018</b>
                  <br>
                  <a href="https://www.reddit.com/r/Chainlink/comments/98ym80/i_dig_the_new_logo/" target="_blank">
                    <u>Info</u>
                  </a>
                </div>

              </div>
            </div>
            
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
          </div>

        </div>


        
        <div v-if="coinId == 'dash'">

            <h3 v-if="$route.name == 'CryptoLogo'">{{ $parent.$parent.coins[coinId].name }} Logo History</h3>
            <h3 v-else >{{ $parent.$parent.coins[coinId].name }} ({{ $parent.$parent.coins[coinId].symbol }})</h3>
          
          <div style="height:20px;"></div>

          <div class="flex-grid">

            <div class="col grid-border">
              <div class="history-grid-container">

                <div class="div-middle-outer">
                  <div class="div-middle-inner">
                    <img :src="'/logos/history/dash-logo-2014.svg?v=001'" class="history-img" :alt="$parent.$parent.coins[coinId].name +'('+ $parent.$parent.coins[coinId].symbol +') old logo 2014'">
                  </div>
                </div>
                
                <div class="div-middle-text history-logo">
                  <b>2014</b>
                  <br>
                </div>
              </div>
            </div>
            
            <div class="col grid-border">
              <div class="history-grid-container">

                <div class="div-middle-outer">
                  <div class="div-middle-inner">
                    <img :src="'/'+'logos/'+coinId+'-'+$parent.$parent.coins[coinId].symbol.toLowerCase()+'-logo.svg?v=001'" class="history-img" style="width:75%;">
                  </div>
                </div>
                
                <div class="div-middle-text history-logo">
                  <b>2018</b>
                  <br>
                </div>

              </div>
            </div>
            
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
          </div>

        </div>


        
        <div v-if="coinId == 'neo'">

          <h3 v-if="$route.name == 'CryptoLogo'">{{ $parent.$parent.coins[coinId].name }} Logo History</h3>
          <h3 v-else >{{ $parent.$parent.coins[coinId].name }} ({{ $parent.$parent.coins[coinId].symbol }})</h3>

          <div style="height:20px;"></div>

          <div class="flex-grid">

            <div class="col grid-border">
              <div class="history-grid-container">

                <div class="div-middle-outer">
                  <div class="div-middle-inner">
                    <img :src="'/logos/history/neo-antshares-logo-2016.png?v=001'" class="history-img" :alt="$parent.$parent.coins[coinId].name +'('+ $parent.$parent.coins[coinId].symbol +') old (Antshares) logo 2016'">
                  </div>
                </div>
                
                <div class="div-middle-text history-logo">
                  <b>2016</b>
                  <br>
                  <a href="https://bitcointalk.org/index.php?topic=1571738.0" target="_blank">
                    <u>Info</u>
                  </a>
                </div>
              </div>
            </div>

            <div class="col grid-border">
              <div class="history-grid-container">

                <div class="div-middle-outer">
                  <div class="div-middle-inner">
                    <img :src="'/logos/history/neo-logo-2017.png?v=001'" class="history-img" :alt="$parent.$parent.coins[coinId].name +'('+ $parent.$parent.coins[coinId].symbol +') old logo 2017'">
                  </div>
                </div>
                
                <div class="div-middle-text history-logo">
                  <b>2017</b>
                  <br>
                  <a href="https://bitcoinmagazine.com/articles/antshares-rebrands-introduces-neo-and-new-smart-economy1" target="_blank">
                    <u>Info</u>
                  </a>
                </div>
              </div>
            </div>
            
            <div class="col grid-border">
              <div class="history-grid-container">

                <div class="div-middle-outer">
                  <div class="div-middle-inner">
                    <img :src="'/'+'logos/'+coinId+'-'+$parent.$parent.coins[coinId].symbol.toLowerCase()+'-logo.svg?v=001'" class="history-img">
                  </div>
                </div>
                
                <div class="div-middle-text history-logo">
                  <b>2020</b>
                  <br>
                </div>

              </div>
            </div>
            
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
          </div>

        </div>



        <div v-if="coinId == 'vertcoin'">

          <h3 v-if="$route.name == 'CryptoLogo'">{{ $parent.$parent.coins[coinId].name }} Logo History</h3>
          <h3 v-else >{{ $parent.$parent.coins[coinId].name }} ({{ $parent.$parent.coins[coinId].symbol }})</h3>

          <div style="height:20px;"></div>

          <div class="flex-grid">

            <div class="col grid-border">
              <div class="history-grid-container">

                <div class="div-middle-outer">
                  <div class="div-middle-inner">
                    <img :src="'/logos/history/vertcoin-logo-2014.png?v=003'" class="history-img" :alt="$parent.$parent.coins[coinId].name +'('+ $parent.$parent.coins[coinId].symbol +') logo'">
                  </div>
                </div>
                
                <div class="div-middle-text history-logo">
                  <b>2014</b>
                  <br>

                </div>
              </div>
            </div>
            
            <div class="col grid-border">
              <div class="history-grid-container">
                <div class="div-middle-outer">
                  <div class="div-middle-inner">
                    <img :src="'/'+'logos/history/vertcoin-logo-2015.svg?v=003'" class="history-img" :alt="$parent.$parent.coins[coinId].name +'('+ $parent.$parent.coins[coinId].symbol +') logo'">
                  </div>
                </div>
                <div class="div-middle-text history-logo">
                  <b>2015</b>
                  <br>
                </div>
              </div>
            </div>

            <div class="col grid-border">
              <div class="history-grid-container">
                <div class="div-middle-outer">
                  <div class="div-middle-inner">
                    <img :src="'/'+'logos/'+coinId+'-'+$parent.$parent.coins[coinId].symbol.toLowerCase()+'-logo.svg?v=003'" class="history-img" :alt="$parent.$parent.coins[coinId].name +'('+ $parent.$parent.coins[coinId].symbol +') logo'">
                  </div>
                </div>
                <div class="div-middle-text history-logo">
                  <b>2019</b>
                  <br>
                </div>
              </div>
            </div>
            
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
          </div>

        </div>



        <div v-if="coinId == 'nimiq'">

            <h3 v-if="$route.name == 'CryptoLogo'">{{ $parent.$parent.coins[coinId].name }} Logo History</h3>
            <h3 v-else >{{ $parent.$parent.coins[coinId].name }} ({{ $parent.$parent.coins[coinId].symbol }})</h3>

          <div style="height:20px;"></div>

          <div class="flex-grid">

            <div class="col grid-border">
              <div class="history-grid-container">

                <div class="div-middle-outer">
                  <div class="div-middle-inner">
                    <img :src="'/logos/history/nimiq-nim-logo-2017.png?v=001'" class="history-img" :alt="$parent.$parent.coins[coinId].name +'('+ $parent.$parent.coins[coinId].symbol +') old Nimiq logo 2017'">
                  </div>
                </div>
                
                <div class="div-middle-text history-logo">
                  <b>2017</b>
                  <br>
                  <a href="https://medium.com/nimiq-network/nimiq-a-peer-to-peer-payment-protocol-native-to-the-web-ffd324bb084" target="_blank">
                    <u>Info</u>
                  </a>
                </div>
              </div>
            </div>
            
            <div class="col grid-border">
              <div class="history-grid-container">

                <div class="div-middle-outer">
                  <div class="div-middle-inner">
                    <img :src="'/'+'logos/'+coinId+'-'+$parent.$parent.coins[coinId].symbol.toLowerCase()+'-logo.svg?v=001'" class="history-img" style="width:75%;">
                  </div>
                </div>
                
                <div class="div-middle-text history-logo">
                  <b>2018</b>
                  <br>
                  <a href="https://www.nimiq.com/blog/a-new-visual-identity/" target="_blank">
                    <u>Info</u>
                  </a>
                </div>

              </div>
            </div>
            
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
          </div>

        </div>




        
        <div v-if="coinId == 'particl'">

            <h3 v-if="$route.name == 'CryptoLogo'">{{ $parent.$parent.coins[coinId].name }} Logo History</h3>
            <h3 v-else >{{ $parent.$parent.coins[coinId].name }} ({{ $parent.$parent.coins[coinId].symbol }})</h3>

          <div style="height:20px;"></div>

          <div class="flex-grid">

            <div class="col grid-border">
              <div class="history-grid-container">

                <div class="div-middle-outer">
                  <div class="div-middle-inner">
                    <img :src="'/logos/history/'+coinId+'-'+$parent.$parent.coins[coinId].symbol.toLowerCase()+'-logo.svg?v=001'" class="history-img" :alt="$parent.$parent.coins[coinId].name +'('+ $parent.$parent.coins[coinId].symbol +') old Nimiq logo 2017'">
                  </div>
                </div>
                
                <div class="div-middle-text history-logo">
                  <b>2017</b>
                </div>
              </div>
            </div>
            
            <div class="col grid-border">
              <div class="history-grid-container">

                <div class="div-middle-outer">
                  <div class="div-middle-inner">
                    <img :src="'/'+'logos/'+coinId+'-'+$parent.$parent.coins[coinId].symbol.toLowerCase()+'-logo.svg?v=001'" class="history-img" style="width:75%;">
                  </div>
                </div>
                
                <div class="div-middle-text history-logo">
                  <b>2021</b>
                  <br>
                </div>

              </div>
            </div>
            
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
          </div>

        </div>


        
        <div v-if="coinId == 'quant'">

            <h3 v-if="$route.name == 'CryptoLogo'">{{ $parent.$parent.coins[coinId].name }} Logo History</h3>
            <h3 v-else >{{ $parent.$parent.coins[coinId].name }} ({{ $parent.$parent.coins[coinId].symbol }})</h3>

          <div style="height:20px;"></div>

          <div class="flex-grid">

            <div class="col grid-border">
              <div class="history-grid-container">

                <div class="div-middle-outer">
                  <div class="div-middle-inner">
                    <img :src="'/logos/history/'+coinId+'-'+$parent.$parent.coins[coinId].symbol.toLowerCase()+'-logo.svg?v=001'" class="history-img" :alt="$parent.$parent.coins[coinId].name +'('+ $parent.$parent.coins[coinId].symbol +') old Nimiq logo 2017'">
                  </div>
                </div>
                
                <div class="div-middle-text history-logo">
                  <b>2018</b>
                </div>
              </div>
            </div>
            
            <div class="col grid-border">
              <div class="history-grid-container">

                <div class="div-middle-outer">
                  <div class="div-middle-inner">
                    <img :src="'/'+'logos/'+coinId+'-'+$parent.$parent.coins[coinId].symbol.toLowerCase()+'-logo.svg?v=001'" class="history-img" style="width:75%;">
                  </div>
                </div>
                
                <div class="div-middle-text history-logo">
                  <b>2021</b>
                  <br>
                </div>

              </div>
            </div>
            
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
          </div>

        </div>

        
        <div v-if="coinId == 'golem-network-tokens'">

            <h3 v-if="$route.name == 'CryptoLogo'">{{ $parent.$parent.coins[coinId].name }} Logo History</h3>
            <h3 v-else >{{ $parent.$parent.coins[coinId].name }} ({{ $parent.$parent.coins[coinId].symbol }})</h3>

          <div style="height:20px;"></div>

          <div class="flex-grid">

            <div class="col grid-border">
              <div class="history-grid-container">

                <div class="div-middle-outer">
                  <div class="div-middle-inner">
                    <img :src="'/logos/history/'+coinId+'-'+$parent.$parent.coins[coinId].symbol.toLowerCase()+'-logo.svg?v=001'" class="history-img" :alt="$parent.$parent.coins[coinId].name +'('+ $parent.$parent.coins[coinId].symbol +') old Nimiq logo 2017'">
                  </div>
                </div>
                
                <div class="div-middle-text history-logo">
                  <b>2016</b>
                </div>
              </div>
            </div>
            
            <div class="col grid-border">
              <div class="history-grid-container">

                <div class="div-middle-outer">
                  <div class="div-middle-inner">
                    <img :src="'/'+'logos/'+coinId+'-'+$parent.$parent.coins[coinId].symbol.toLowerCase()+'-logo.svg?v=001'" class="history-img" style="width:75%;">
                  </div>
                </div>
                
                <div class="div-middle-text history-logo">
                  <b>2021</b>
                  <br>
                </div>

              </div>
            </div>
            
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
          </div>

        </div>

        
        <div v-if="coinId == 'gnosis-gno'">

            <h3 v-if="$route.name == 'CryptoLogo'">{{ $parent.$parent.coins[coinId].name }} Logo History</h3>
            <h3 v-else >{{ $parent.$parent.coins[coinId].name }} ({{ $parent.$parent.coins[coinId].symbol }})</h3>

          <div style="height:20px;"></div>

          <div class="flex-grid">

            <div class="col grid-border">
              <div class="history-grid-container">

                <div class="div-middle-outer">
                  <div class="div-middle-inner">
                    <img :src="'/logos/history/'+coinId+'-'+$parent.$parent.coins[coinId].symbol.toLowerCase()+'-logo.svg?v=001'" class="history-img" :alt="$parent.$parent.coins[coinId].name +'('+ $parent.$parent.coins[coinId].symbol +') old Nimiq logo 2017'">
                  </div>
                </div>
                
                <div class="div-middle-text history-logo">
                  <b>2017</b>
                </div>
              </div>
            </div>
            
            <div class="col grid-border">
              <div class="history-grid-container">

                <div class="div-middle-outer">
                  <div class="div-middle-inner">
                    <img :src="'/'+'logos/'+coinId+'-'+$parent.$parent.coins[coinId].symbol.toLowerCase()+'-logo.svg?v=001'" class="history-img" style="width:75%;">
                  </div>
                </div>
                
                <div class="div-middle-text history-logo">
                  <b>2021</b>
                  <br>
                </div>

              </div>
            </div>
            
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
          </div>

        </div>

        
        <div v-if="coinId == 'bnb'">

            <h3 v-if="$route.name == 'CryptoLogo'">{{ $parent.$parent.coins[coinId].name }} Logo History</h3>
            <h3 v-else >{{ $parent.$parent.coins[coinId].name }} ({{ $parent.$parent.coins[coinId].symbol }})</h3>

          <div style="height:20px;"></div>

          <div class="flex-grid">

            <div class="col grid-border">
              <div class="history-grid-container">

                <div class="div-middle-outer">
                  <div class="div-middle-inner">
                    <img :src="'/logos/history/'+coinId+'-'+$parent.$parent.coins[coinId].symbol.toLowerCase()+'-logo.svg?v=001'" class="history-img" :alt="$parent.$parent.coins[coinId].name +'('+ $parent.$parent.coins[coinId].symbol +') old Nimiq logo 2017'">
                  </div>
                </div>
                
                <div class="div-middle-text history-logo">
                  <b>2017</b>
                </div>
              </div>
            </div>
            
            <div class="col grid-border">
              <div class="history-grid-container">

                <div class="div-middle-outer">
                  <div class="div-middle-inner">
                    <img :src="'/'+'logos/'+coinId+'-'+$parent.$parent.coins[coinId].symbol.toLowerCase()+'-logo.svg?v=001'" class="history-img" style="width:75%;">
                  </div>
                </div>
                
                <div class="div-middle-text history-logo">
                  <b>2022</b>
                  <br>
                </div>

              </div>
            </div>
            
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
          </div>

        </div>

        
        <div v-if="coinId == 'idex'">

            <h3 v-if="$route.name == 'CryptoLogo'">{{ $parent.$parent.coins[coinId].name }} Logo History</h3>
            <h3 v-else >{{ $parent.$parent.coins[coinId].name }} ({{ $parent.$parent.coins[coinId].symbol }})</h3>

          <div style="height:20px;"></div>

          <div class="flex-grid">

            <div class="col grid-border">
              <div class="history-grid-container">

                <div class="div-middle-outer">
                  <div class="div-middle-inner">
                    <img :src="'/logos/history/'+coinId+'-'+$parent.$parent.coins[coinId].symbol.toLowerCase()+'-logo.svg?v=001'" class="history-img" :alt="$parent.$parent.coins[coinId].name +'('+ $parent.$parent.coins[coinId].symbol +') old Nimiq logo 2017'">
                  </div>
                </div>
                
                <div class="div-middle-text history-logo">
                  <b>2019</b>
                </div>
              </div>
            </div>
            
            <div class="col grid-border">
              <div class="history-grid-container">

                <div class="div-middle-outer">
                  <div class="div-middle-inner">
                    <img :src="'/'+'logos/'+coinId+'-'+$parent.$parent.coins[coinId].symbol.toLowerCase()+'-logo.svg?v=001'" class="history-img" style="width:75%;">
                  </div>
                </div>
                
                <div class="div-middle-text history-logo">
                  <b>2022</b>
                  <br>
                </div>

              </div>
            </div>
            
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
          </div>

        </div>

        
        <div v-if="coinId == 'mth'">

            <h3 v-if="$route.name == 'CryptoLogo'">{{ $parent.$parent.coins[coinId].name }} Logo History</h3>
            <h3 v-else >{{ $parent.$parent.coins[coinId].name }} ({{ $parent.$parent.coins[coinId].symbol }})</h3>

          <div style="height:20px;"></div>

          <div class="flex-grid">

            <div class="col grid-border">
              <div class="history-grid-container">

                <div class="div-middle-outer">
                  <div class="div-middle-inner">
                    <img :src="'/logos/history/'+coinId+'-'+$parent.$parent.coins[coinId].symbol.toLowerCase()+'-logo.svg?v=001'" class="history-img" :alt="$parent.$parent.coins[coinId].name +'('+ $parent.$parent.coins[coinId].symbol +') old Nimiq logo 2017'">
                  </div>
                </div>
                
                <div class="div-middle-text history-logo">
                  <b>2017</b>
                </div>
              </div>
            </div>
            
            <div class="col grid-border">
              <div class="history-grid-container">

                <div class="div-middle-outer">
                  <div class="div-middle-inner">
                    <img :src="'/'+'logos/'+coinId+'-'+$parent.$parent.coins[coinId].symbol.toLowerCase()+'-logo.svg?v=001'" class="history-img" style="width:75%;">
                  </div>
                </div>
                
                <div class="div-middle-text history-logo">
                  <b>2022</b>
                  <br>
                </div>

              </div>
            </div>
            
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
          </div>

        </div>

        
        <div v-if="coinId == 'alpha-finance-lab'">

            <h3 v-if="$route.name == 'CryptoLogo'">{{ $parent.$parent.coins[coinId].name }} Logo History</h3>
            <h3 v-else >{{ $parent.$parent.coins[coinId].name }} ({{ $parent.$parent.coins[coinId].symbol }})</h3>

          <div style="height:20px;"></div>

          <div class="flex-grid">

            <div class="col grid-border">
              <div class="history-grid-container">

                <div class="div-middle-outer">
                  <div class="div-middle-inner">
                    <img :src="'/logos/history/'+coinId+'-'+$parent.$parent.coins[coinId].symbol.toLowerCase()+'-logo.svg?v=001'" class="history-img" :alt="$parent.$parent.coins[coinId].name +'('+ $parent.$parent.coins[coinId].symbol +') old Nimiq logo 2017'">
                  </div>
                </div>
                
                <div class="div-middle-text history-logo">
                  <b>2020</b>
                </div>
              </div>
            </div>
            
            <div class="col grid-border">
              <div class="history-grid-container">

                <div class="div-middle-outer">
                  <div class="div-middle-inner">
                    <img :src="'/'+'logos/'+coinId+'-'+$parent.$parent.coins[coinId].symbol.toLowerCase()+'-logo.svg?v=001'" class="history-img" style="width:75%;">
                  </div>
                </div>
                
                <div class="div-middle-text history-logo">
                  <b>2021</b>
                  <br>
                  <a href="https://blog.alphafinance.io/introducing-the-new-visual-brand-of-alpha-finance-lab/" target="_blank"><u>Info</u></a>
                </div>

              </div>
            </div>
            
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
            <div class="col col-empty"></div>
          </div>

        </div>

    </div>
</template>

<script>
export default {
    props: ['coinId'],
    mounted: function () {
        console.log(this.coinId);
    }
}
</script>