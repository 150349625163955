<template>
  <div v-cloak v-if="($route.name == 'CoinClothingLaptopSleeve') || ($route.name == 'CoinClothing') || ($route.name == 'CryptoLaptopSleeves')">
    
    <div class="grid-product">
      <div>
        <router-link :to="'/merch/'+title+'/laptop-sleeve'">
          <div class="crypto-product">
            <img v-if="color == 'White'" :src="'/'+'merch/'+title+'-'+$parent.$parent.coins[title].symbol.toLowerCase()+'-laptop-sleeve-white.jpg'+version" width="274" class="select-none" draggable="false"  :alt="$parent.$parent.coins[title].name +' ('+ $parent.$parent.coins[title].symbol +') Crypto Laptop Sleeve (Case) White'">
            <!-- <img v-if="color == 'Black'" :src="'/'+'merch/'+title+'-'+$parent.$parent.coins[title].symbol.toLowerCase()+'-laptop-sleeve-black.jpg'+version" width="274" class="select-none" draggable="false"  :alt="$parent.$parent.coins[title].name +' ('+ $parent.$parent.coins[title].symbol +') Crypto Sweatshirt Black'"> -->
          </div>
        </router-link>
      </div>

      <div class="product-content">
        <router-link :to="'/merch/'+title+'/laptop-sleeve'">
          <span class="product-title">{{ $parent.$parent.coins[title].name }} ({{ $parent.$parent.coins[title].symbol }}) Laptop Sleeve</span>
        </router-link>
        <br>
        <router-link :to="'/merch/'+title+'/laptop-sleeve'" class="link-details link-hover">Form-Fitting Laptop Case</router-link>
        <div style="height:15px;"></div>

        <span>
          <div class="product-cell-left">Price</div>
          <div class="product-cell-left">${{price}}</div>

          <div style="height:16px;clear:both;"></div>

          <div class="product-cell-left">Size</div>
          <div class="product-cell-left">
            <select v-model="size" style="width:65px;">
              <option v-for="(value, key) in $parent.$parent.products['cc-laptop-sleeve'].sizesPrices" :key="key" v-bind:value="key">
                {{ key }}
              </option>
            </select>
          </div>

          <!-- <div style="height:12px;clear:both;"></div>

          <div class="product-cell-left">Color</div>
          <div class="product-cell-left">
            <select v-model="color" style="width:65px;">
              <option v-for="(value, key) in $parent.$parent.products['cc-laptop-sleeve'].colors" :key="key" v-bind:value="key">
                {{ key }}
              </option>
            </select>
          </div> -->

          <div style="height:12px;clear:both;"></div>

          <div class="product-cell-left">Quantity</div>
          <div class="product-cell-left">
            <input v-model="amount" type="number" value="1" style="padding-left:4px;width:59px;padding-top:2px;">
          </div>

          <div style="height:25px;clear:both;"></div>

          
          <button v-if="available" @click="addToCart('cc-laptop-sleeve')">Add to cart</button>
          <button v-else disabled>Out of Stock</button>

          <div v-if="showAddedLink" style="margin-top:10px;font-size:14px;position:absolute;">
            Added to <router-link to="/shopping-cart" class="underline">Shopping Cart</router-link>
          </div>
        </span>
      </div>

    </div>

    <div style="clear:both;padding-top:40px;" v-if="$route.name == 'CoinClothingLaptopSleeve'">
      <b>Description</b>
      <br><br>
      This lightweight, form-fitting {{ $parent.$parent.coins[title].name }} ({{ $parent.$parent.coins[title].symbol }}) laptop sleeve is a must-have for any laptop owner on the go. To prevent any scratches, it contains a padded zipper binding and its interior is lined with faux fur. What’s more, it’s made from a water-resistant and scratch-proof material, making sure that both the laptop and the sleeve design are intact from day to day.
      <br><br>
      100% neoprene
      <br>
      Snug fit
      <br>
      Faux fur interior lining
      <br>
      Solid black rear
      <br>
      Lightweight and resistant to water, oil, and heat
      <br>
      Top-loading zippered enclosure with two sliders
      <br>
      Padded zipper binding
      <br>
      <br>
      Product weight: 
      <br>
      13'': 6.49 oz. (220 g)
      <br>
      15'': 7.67 oz. (260 g)
      <br>
      <br>
      The laptop sleeve fits most 13'' and 15'' laptops. See our size guide for more in-depth details.
      <div style="height:60px;"></div>
      <b>Size guide</b>
      <br><br>
      <div>
      US sizes (In)<div id="size-guide-12" class="wysiwyg">
      <div class="size-guide"><table class="size-guide__table"><thead><tr><td></td><td>13 IN</td><td>15 IN</td></tr></thead><tbody><tr><td class="size-guide__table-first-row"><span>Length</span></td><td>13 ½</td><td>14 ¾</td></tr><tr><td class="size-guide__table-second-row"><span>Width</span></td><td>10 ½</td><td>11 ¼</td></tr><tr><td class="size-guide__table-third-row"><span>Height</span></td><td>⅝</td><td>⅝</td></tr></tbody></table><div class="margin-top-15"></div></div>
      </div></div>
      <br>
      <div>
      Metric sizes (Cm)<div id="size-guide-12" class="wysiwyg">
      <div class="size-guide"><table class="size-guide__table"><thead><tr><td></td><td>13 IN</td><td>15 IN</td></tr></thead><tbody><tr><td class="size-guide__table-first-row"><span>Length</span></td><td>34.3</td><td>37.3</td></tr><tr><td class="size-guide__table-second-row"><span>Width</span></td><td>26.7</td><td>28.4</td></tr><tr><td class="size-guide__table-third-row"><span>Height</span></td><td>1.5</td><td>1.5</td></tr></tbody></table><div class="margin-top-15"></div></div>
      </div></div>
    </div>

  </div>
</template>

<script>

export default {
  name: 'CryptoLaptopSleeve',
  props: [ 'coin', 'available' ],
  data() {
    return {
      version: '',

      size: '15in',
      color: 'White',
      amount: 1,
      showAddedLink: false,

      dress2nd: false,
    }
  },
  beforeMount() {
    this.title = this.coin;
    this.version = assetsVersion;
  },
  mounted() {
  },
  computed: {
    price() {
      return this.$parent.$parent.products['cc-laptop-sleeve'].sizesPrices[this.size]
    },
  },
  methods: {

    addToCart: function () {

      this.showAddedLink = true;

      // console.log('hoo');
      let item_name = 'Laptop Sleeve';
      let item_name_full = this.$parent.$parent.coins[this.coin].name+' ('+this.$parent.$parent.coins[this.coin].symbol+') ' + item_name;
      let item_id = 'cc-laptop-sleeve-'+this.size+'-'+this.$route.params.coinId+'-'+this.color;
      let item_price = this.price;
      let item_amount = this.amount;
      let item_size = this.size;
      let item_config = { color: this.color };


      dexie.products.put({item_id: item_id, item_name: item_name, item_name_full: item_name_full, item_size: item_size, item_price: item_price, item_amount: item_amount, item_config: item_config, coin_id: this.coin })
      .then((id) => {
        this.$parent.$emit('countRefresh');
      });
    },

    dressSecond() {
      this.dress2nd = true;
    },

    dressFirst() {
      this.dress2nd = false;
    },

  }
};
</script>