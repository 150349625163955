<template>
  <div v-cloak v-if="($route.name == 'CoinClothingHodlTshirt') || ($route.name == 'CoinClothing') || ($route.name == 'CryptoFashion')">

    <div class="grid-product">
      
      <div>
        <router-link :to="'/merch/'+title+'/hodl-t-shirt'">
          <div class="crypto-product">
            <img v-if="color == 'Black'" :src="'/'+'merch/'+title+'-'+$parent.$parent.coins[title].symbol.toLowerCase()+'-hodl-t-shirt-black.jpg'+version" width="278" class="select-none" draggable="false"  :alt="$parent.$parent.coins[title].name +' ('+ $parent.$parent.coins[title].symbol +') Crypto HODL T-Shirt Black'">
            <img v-if="color == 'White'" :src="'/'+'merch/'+title+'-'+$parent.$parent.coins[title].symbol.toLowerCase()+'-hodl-t-shirt-white.jpg'+version" width="278" class="select-none" draggable="false"  :alt="$parent.$parent.coins[title].name +' ('+ $parent.$parent.coins[title].symbol +') Crypto HODL T-Shirt White'">
          </div>
        </router-link>
      </div>

      <div class="product-content">
        <router-link :to="'/merch/'+title+'/hodl-t-shirt'"><span class="product-title">{{ $parent.$parent.coins[title].name }} ({{ $parent.$parent.coins[title].symbol }}) HODL T-shirt</span></router-link>
        <br>
        <router-link :to="'/merch/'+title+'/hodl-t-shirt'" class="link-details link-hover">Gildan 64000 Unisex Softstyle</router-link>

        <div style="height:15px;"></div>

        <div class="product-cell-left">Price</div>
        <div class="product-cell-left">${{hodlTshirtPrice}}</div>

        <div style="height:16px;clear:both;"></div>

        <div class="product-cell-left">Size</div>
        <div class="product-cell-left">
          <select v-model="hodlTshirtSize" style="width:65px;">
            <option v-for="(value, key) in $parent.$parent.products['cc-hodl-tshirt'].sizesPrices" :key="key" v-bind:value="key">
              {{ key }}
            </option>
          </select>
        </div>

        <div style="height:12px;clear:both;"></div>

        <div class="product-cell-left">Color</div>
        <div class="product-cell-left">
          <select v-model="color" style="width:65px;">
            <option v-for="(value, key) in $parent.$parent.products['cc-hodl-tshirt'].colors" :key="key" v-bind:value="key">
              {{ key }}
            </option>
          </select>
        </div>

        <div style="height:12px;clear:both;"></div>

        <div class="product-cell-left">Quantity</div>
        <div class="product-cell-left">
          <input v-model="hodlTshirtAmount" type="number" value="1" style="padding-left:4px;width:59px;padding-top:2px;">
        </div>
        
        <div style="height:25px;clear:both;"></div>

        
        <button v-if="available" @click="addToCart('cc-hodl-tshirt')">Add to cart</button>
        <button v-else disabled>Out of Stock</button>


        <div v-if="showAddedLinkHodlTshirt" style="margin-top:10px;font-size:14px;position:absolute;">
          Added to <router-link to="/shopping-cart" class="underline">Shopping Cart</router-link>
        </div>
      </div>

    </div>

    <div style="clear:both;padding-top:40px;" v-if="$route.name == 'CoinClothingHodlTshirt'">
      <b>Description</b>
      <br>
      <br>
      HODL is a popular crypto meme that originated on the Bitcointalk forum. Someone made a mistake while writing the world HOLD, and hence it became HODL. The crypto community started using the word HODL, as in hodling and hodler. It is often being used in the context when the cryptocurrency market trend starts going upwards. Then fellow hodlers are urging each other to hodl onto their cryptocurrencies, as opposed to selling them.
      <br>
      <br>
      For this {{ $parent.$parent.coins[title].name }} ({{ $parent.$parent.coins[title].symbol }}) HODL t-shirt, the Gildan 64000 model is a great choice. It's a classic t-shirt that compliments any outfit. It's made of a heavier cotton and the double-stitched neckline and sleeves give it more durability, so it can become an everyday favorite.
      <br><br>
      100% ring-spun cotton<br>
      Fabric weight: 4.5 oz/y² (153 g/m²)<br>
      Pre-shrunk for extra durability<br>
      Shoulder-to-shoulder taping<br>
      Quarter-turned to avoid crease down the center<br>
      <br>
      The male model in the above photo is wearing a size M. He's 6.2 feet (190 cm) tall, chest circumference 37.7" (96 cm), waist circumference 33.4" (85 cm). 
      <div style="height:60px;"></div>
      <b>Size guide</b>
      <br><br>
      <div>
      US sizes (In)<div id="size-guide-12" class="wysiwyg">
      <div class="size-guide"><table class="size-guide__table"><thead><tr><td></td><td>S</td><td>M</td><td>L</td><td>XL</td><td>2XL</td><td>3XL</td></tr></thead><tbody><tr><td class="size-guide__table-first-row"><span>Length</span></td><td>28</td><td>29</td><td>30</td><td>31</td><td>32</td><td>33</td></tr><tr><td class="size-guide__table-first-row"><span>Width</span></td><td>18</td><td>20</td><td>22</td><td>24</td><td>26</td><td>28</td></tr></tbody></table><div class="margin-top-15"></div></div></div>
      </div>
      <br>
      <div>
      Metric sizes (Cm)<div id="size-guide-12" class="wysiwyg">
      <div class="size-guide"><table class="size-guide__table"><thead><tr><td></td><td>S</td><td>M</td><td>L</td><td>XL</td><td>2XL</td><td>3XL</td></tr></thead><tbody><tr><td class="size-guide__table-first-row"><span>Length</span></td><td>71.1</td><td>73.7</td><td>76.2</td><td>78.7</td><td>81.3</td><td>83.8</td></tr><tr><td class="size-guide__table-first-row"><span>Width</span></td><td>45.7</td><td>50.8</td><td>55.9</td><td>61</td><td>66</td><td>71.1</td></tr></tbody></table><div class="margin-top-15"></div></div></div>
      <br>
      Measurements are provided by suppliers.
      <br>
      Product measurements may vary by up to 2" (5 cm).
      </div>
      <div style="clear:both;height:40px;"></div>
      <b>Care instructions</b>
      <br>
      <br>      
      - Machine wash cold, inside-out, gentle cycle with mild detergent and similar colors. Use non-chlorine bleach, only when necessary. No fabric softeners.<br>
      - Tumble dry low, or hang-dry for longest life.<br>
      - Cool iron inside-out if necessary. Do not iron decoration.<br>
      - Do not dry clean.
    </div>

  </div>
</template>

<script>

export default {

  name: 'CryptoHodlTshirt',
  props: [ 'coin', 'index', 'available' ],
  data() {
    return {
      version: '',
      amount: 1,
      showAddedLinkHodlTshirt: false,
      color: "Black",
      hodlTshirtSize: "L",
      hodlTshirtAmount: 1,
    }
  },
  beforeMount() {
    this.title = this.coin;
    
    this.version = assetsVersion;
    // if(this.index % 2 == 0) {
    //   this.color = "White"
    // }
    // else {
    //   this.color = "Black"
    // }
  },
  
  mounted() {
  },
  computed: {
    hodlTshirtPrice() {
      return this.$parent.$parent.products['cc-hodl-tshirt'].sizesPrices[this.hodlTshirtSize]
    }
  },
  methods: {

    addToCart: function (type) {

      this.showAddedLinkHodlTshirt = true;

      let item_name = 'HODL T-shirt';
      let item_name_full = this.$parent.$parent.coins[this.coin].name+' ('+this.$parent.$parent.coins[this.coin].symbol+') ' + item_name;
      let item_id = 'cc-hodl-tshirt-'+this.hodlTshirtSize+'-'+this.coin+'-'+this.color;
      let item_price = this.hodlTshirtPrice;
      let item_amount = this.hodlTshirtAmount;
      let item_size = this.hodlTshirtSize;
      let item_config = { color: this.color };

      dexie.products.put({item_id: item_id, item_name: item_name, item_name_full: item_name_full, item_size: item_size, item_price: item_price, item_amount: item_amount, item_config: item_config, coin_id: this.coin })
      .then((id) => {
        this.$parent.$emit('countRefresh');
      });
    },

  }
};
</script>